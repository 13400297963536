import { Drawer, Image, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import ButtonComponent from "../../common/button";
import { HashLink } from "react-router-hash-link";
import AuthFooterComponent from "../../common/footer";
import { MAIN_APP_LINK } from "../../../constants";

const { Header } = Layout;

const MainLayout = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const showDrawer = () => {
    console.log("showing drawer");
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };
  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <Link to="/">
          <div className={styles.logo}>
            <Image src="/Images/Imprint-live.png" alt="Logo" preview={false} />
          </div>
        </Link>

        {/* Show menu in Drawer only on mobile */}
        {isMobile ? (
          <>
            <ButtonComponent
              {...{
                text: <MenuOutlined />,
                onClick: showDrawer,
              }}
            />
            <Drawer
              title="Menu"
              placement="left"
              onClose={closeDrawer}
              visible={visible}
              className={styles.drawer}
            >
              <Menu mode="vertical">
                <Menu.Item>
                  <Link to="/about-us">About</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/about-us/#our-team">Team</Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="https://support.imprint.live/hc/en-us">
                    Support
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to={`${MAIN_APP_LINK}/login`}>
                    <ButtonComponent
                      {...{ type: "primary", text: "Sign In" }}
                    />
                  </Link>
                </Menu.Item>
              </Menu>
            </Drawer>
          </>
        ) : (
          <Menu mode="horizontal" className={styles.menu}>
            <Menu.Item>
              <Link to="/about-us">About</Link>
            </Menu.Item>
            <Menu.Item>
              <HashLink smooth to="/about-us/#our-team">
                Team
              </HashLink>
            </Menu.Item>
            <Menu.Item>
              {" "}
              <Link to="https://support.imprint.live/hc/en-us">Support</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to={`${MAIN_APP_LINK}/login`}>
                <ButtonComponent {...{ type: "primary", text: "Sign In" }} />
              </Link>
            </Menu.Item>
          </Menu>
        )}
      </Header>
      {children}

      <AuthFooterComponent />
    </Layout>
  );
};

export default MainLayout;
