import React from "react";
import { Row, Col, Card, Image } from "antd";
import styles from "./index.module.scss";
import { Link } from "react-router-dom";
import MainLayout from "../../components/layout/main-layout";
import ButtonComponent from "../../components/common/button";
import { MAIN_APP_LINK } from "../../constants";

const AboutUs = () => {
  return (
    <MainLayout>
      <div id="hero" className={styles.storyContainer}>
        <Row
          gutter={[32, 32]}
          align="middle"
          justify="center"
          className={styles.storyRow}
        >
          <Col xs={22} sm={20} md={16} lg={14} className={styles.textSection}>
            <Card bordered={false} className={styles.cardContent}>
              <h2>Stories make us human</h2>
              <p>
                Stories are how we have always made sense of the world. Now more
                than ever, as we evolve and navigate a new era in which we
                coexist with technology, stories are fundamental to what makes
                us human.
              </p>
              <p>
                Our platform is designed to help nurture true friendship –
                Buddyhood – where everyone feels they can belong in even the
                most unsettling of times. Our purpose is to be a buddy to our
                users to help them feel supported so that they can thrive.
              </p>
              {/* <p>
                Stories define how future dialogues made us one of the world's
                most interactive species. From the book cover, ad, a website and
                moving into reels as a hub that reveals what binds technology
                solutions to fundamentals in what makes us human.
              </p>
              <p>
                Our in-depth, high human AI is a facilitating study proof –
                which everyone loves. They can bask in even the most small tiny
                written lines. Our purpose in this is to study our readers to
                help them fully appreciate what they carry today. */}
              {/* </p> */}
            </Card>
          </Col>

          {/* Image Section */}
          <Col xs={22} sm={20} md={16} lg={10} className={styles.imageSection}>
            <Image
              src="/Images/stories.png"
              alt="Illustration"
              className={styles.storyImage}
              loading="lazy"
              preview={false}
            />
          </Col>
        </Row>
      </div>

      <div id="founder-box" className={styles.aboutContainer}>
        <Row
          gutter={[32, 32]}
          align="middle"
          justify="center"
          className={styles.aboutRow}
        >
          {/* Image Section */}
          <Col xs={22} sm={20} md={16} lg={10} className={styles.imageSection}>
            <Image
              src="/Images/founders.png"
              alt="Founders"
              className={styles.aboutImage}
              loading="lazy"
              preview={false}
            />
          </Col>

          {/* Text Section */}
          <Col xs={22} sm={20} md={16} lg={14} className={styles.textSection}>
            <div className={styles.cardContentBlack}>
              <h2>About us</h2>
              <p>Harleen and Andrew are Imprint's founders and authors.</p>
              <p>
                We are parents, who believe, like all parents, that our
                children, and every child for that matter, deserves a better
                world. Post the pandemic, society has become fragmented and
                emotional dysregulation, compounded with social media, is
                causing a generational divide and mental health crisis.
              </p>
              <p>
                <strong>
                  It's not fair and we want to do something about it.{" "}
                </strong>
              </p>
              <p>
                We want to champion every voice because it's not easy and
                everyone is trying their hardest. We care and through our
                platform we hope to bring people together in a safer space
                online, to empower and support each other and create a lasting
                positive impact.{" "}
              </p>

              <p>
                Our collaborative work is rooted in diversity of thought and a
                profound friendship; our Buddyhood. It has helped us navigate
                difficult times and provided us with a sense of belonging.
                Through our platform Imprint.live, The Buddyhood books that we
                publish, and all our resources, we hope to spread that feeling
                of belonging with others
              </p>
              {/* <p>
                We want to champion children and young people because it is not
                easy and everyone is trying their hardest. We can add our books
                to bring people together, while allowing the warmth of a hug,
                something that we would have appreciated time times were tough.
              </p>
              <p>
                Our collaborative work is rooted in diversity of thought and a
                proud multicultural background. This has helped us navigate
                difficult friendships and guided us with a sense of belonging.
                Through The Buddyhood books that we publish, and all our
                resources, we hope to spread that feeling of belonging with
                others.
              </p> */}
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.foundersContainer} id="our-team">
        <Row
          gutter={[32, 32]}
          align="middle"
          justify="center"
          className={styles.foundersRow}
        >
          {/* Text Section */}
          <Col xs={22} sm={20} md={16} lg={14} className={styles.textSection}>
            <Card bordered={false} className={styles.cardContent}>
              <h2>Founders & Partners</h2>
              {/* <h3 className={styles.highlight}>Harleen & Andrew</h3>
              <p className={styles.subText}>Our Co-founders</p>
              <p>
                They share a vision of a world where authenticity and positive
                change cost little. For Harleen and Andrew, every lie is worth
                living and their work reflects this core belief.
              </p>
              <p>
                Harleen and Andrew are also authors and use the power of
                storytelling to create a positive impact. Find out more about
                their stories with purpose on thebuddyhood.com.
              </p> */}

              <p>
                Together with our partners, we share a vision of a world where
                authenticity and positivity thrive. Where every life is worth
                living and our work reflects this core belief.
              </p>

              <p>
                We are also authors, using the power of storytelling to create a
                positive impact that continues long after the last page. Find
                out more about our stories with purpose at{" "}
                <a 
                  href="https://www.thebuddyhood.com" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  style={{
                    color: "#000",
                    textDecoration: "underline",
                  }}
                >
                  www.thebuddyhood.com
                </a>
                .
              </p>
            </Card>
          </Col>

          {/* Image Section */}
          <Col xs={22} sm={20} md={16} lg={10} className={styles.imageSection}>
            <Image
              src="/Images/partners.png"
              alt="Founders and Partners"
              className={styles.foundersImage}
              loading="lazy"
              preview={false}
            />
          </Col>
        </Row>
      </div>

      <div id="buddyhood-section" className={styles.buddyhoodContainer}>
        <h2 className={styles.title}>The Buddyhood</h2>
        <p className={styles.subtitle}>
          An independent publishing house that shares stories with purpose to:
        </p>

        <Row gutter={[16, 16]} justify="center" className={styles.cardsRow}>
          <Col xs={22} sm={20} md={8} lg={6}>
            <div className={styles.infoCard}>
              <div className={styles.imgbox}>
                <Image
                  src="/Images/Empower.png"
                  alt="Empower"
                  className={styles.icon}
                  loading="lazy"
                  preview={false}
                />
              </div>
              <div className={styles.blackbox}>
                <h3>EMPOWER</h3>
              </div>
            </div>
          </Col>
          <Col xs={22} sm={20} md={8} lg={6}>
            <div className={styles.infoCard}>
              <div className={styles.imgbox}>
                <Image
                  src="/Images/Educate.png"
                  alt="Educate"
                  className={styles.icon}
                  loading="lazy"
                  preview={false}
                />
              </div>
              <div className={styles.blackbox}>
                <h3>EDUCATE</h3>
              </div>
            </div>
          </Col>
          <Col xs={22} sm={20} md={8} lg={6}>
            <div className={styles.infoCard}>
              <div className={styles.imgbox}>
                <Image
                  src="/Images/entertain.png"
                  alt="Inspire"
                  className={styles.icon}
                  loading="lazy"
                  preview={false}
                />
              </div>
              <div className={styles.blackbox}>
                <h3>INSPIRE</h3>
              </div>
            </div>
          </Col>
        </Row>

        <p className={styles.description}>
          Each story raises awareness and gives back to charitable causes that
          can drive positive change.
        </p>
        <Link to={`${MAIN_APP_LINK}/signup`}>
          <ButtonComponent
            {...{
              type: "primary",
              text: "About Our Mission",
              variant: "gold",
            }}
          />
        </Link>
      </div>

      {/* <AuthFooterComponent />
      <span className="app-version">v{process?.env?.version}</span> */}
    </MainLayout>
  );
};

export default AboutUs;
