import { Button } from "antd";
import styles from "./index.module.scss";

const ButtonComponent = (props) => {
  const { text, children, variant, onClick } = props;

  return (
    <Button
      {...{
        ...props,
        className: styles[`${variant || "black"}-button`],
        onClick,
      }}
    >
      {text || children}
    </Button>
  );
};

export default ButtonComponent;
