import React from "react";
import "./index.scss";
import { Col, Divider, Image, Row } from "antd";
import ButtonComponent from "../../../../components/common/button";
import { Link } from "react-router-dom";
import { MAIN_APP_LINK } from "../../../../constants";

const PlanCard = (props) => {
  const {
    planName,
    price,
    description,
    charityFactor,
    perks,
    // priceId,
    MARGIN,
    currentPlanPage,
    // id,
    FONT_SIZE,
    IMAGE_HEIGHT,
  } = props;

  const isBasicPlan = planName === "Basic";

  const renderButton = () => {
    // if (!currentPlanPage) {
    //   return (
    //     <ButtonComponent
    //       disabled={true}
    //       type="default"
    //       text="Become A Member"
    //       block
    //     />
    //   );
    // }

    if (isBasicPlan) {
      return (
        <Link to={`${MAIN_APP_LINK}/signup`}>
          <ButtonComponent
            type="primary"
            text="Become A Member"
            block
            style={{
              backgroundColor: "#000000",
              borderColor: "#000000",
              color: "#ffffff",
              height: "40px",
            }}
            className="basic-plan-button"
          />
        </Link>
      );
    }

    return (
      <ButtonComponent
        type="default"
        text="Coming Soon"
        block
        disabled={true}
        style={{
          height: "40px",
        }}
      />
    );
  };

  return (
    <div
      className="plan-card-container"
      style={{ paddingBottom: !currentPlanPage && "0px" }}
    >
      <div className={`plan-visual-card`} style={{ height: IMAGE_HEIGHT }}>
        <Image
          preview={false}
          src="/Images/subscription-plan.png"
          style={{
            marginTop: `${MARGIN}px`,
            transform: "scale(1.6)",
          }}
        />
      </div>
      <Row align="middle" justify="space-between" className="card-heading">
        <h2>{planName}</h2>
        <p>
          <strong>£{price}</strong>/month
        </p>
      </Row>
      <p className="card-description">{description}</p>
      {renderButton()}
      <Row align="middle" justify="space-between" className="card-details">
        <Col span={12} className="divider">
          <strong style={{ fontSize: FONT_SIZE }}>£{charityFactor}</strong>
          <p>Monthly Charity</p>
        </Col>
        <Col span={12}>
          <strong style={{ fontSize: FONT_SIZE }}>{perks}x</strong>
          <p>Like & Imprint Power</p>
        </Col>
      </Row>
      {currentPlanPage && (
        <>
          <Divider />
          <Row justify="center" className="footer-text">
            <p>Text and image Imprints</p>
            <p>£0.89/month platform charges</p>
          </Row>
        </>
      )}
    </div>
  );
};

export default PlanCard;
