import { Col, Image, Row } from "antd";

import "./index.scss";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  TwiterIcon,
} from "../../../assets/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { MAIN_APP_LINK } from "../../../constants";

export default function AuthFooterComponent() {
  return (
    <>
      <footer>
        <div className="container">
          <Row justify="center" align="middle">
            <Col span={24}>
              <ul className="footer-social">
                <li>
                  <Link
                    to="https://twitter.com/imprintlive5"
                    title=""
                    target="_blank"
                  >
                    <Image
                      preview={false}
                      src={TwiterIcon}
                      alt="Imprint.live"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.facebook.com/profile.php?id=61553164695687"
                    title=""
                    target="_blank"
                  >
                    <Image
                      preview={false}
                      src={FacebookIcon}
                      alt="Imprint.live"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/imprint.live/"
                    title=""
                    target="_blank"
                  >
                    <Image
                      preview={false}
                      src={InstagramIcon}
                      alt="Imprint.live"
                    />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/imprintlive/"
                    title=""
                    target="_blank"
                  >
                    <Image
                      preview={false}
                      src={LinkedinIcon}
                      alt="Imprint.live"
                    />
                  </Link>
                </li>
              </ul>
            </Col>
            <Col span={24}>
              <ul className="footer-nav">
                <li>
                  <Link
                    to={`${MAIN_APP_LINK}/about-imprint`}
                    title="About Us"
                    target="_blank"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${MAIN_APP_LINK}/accessibility`}
                    title="Accessibility"
                    target="_blank"
                  >
                    Accessibility
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://support.imprint.live/hc/en-us"
                    title="Support Center"
                    target="_blank"
                  >
                    Support center
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${MAIN_APP_LINK}/community-guidelines`}
                    title="Community Guidelines"
                    target="_blank"
                  >
                    Community Guidelines
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${MAIN_APP_LINK}/content-policy`}
                    title="Content Policy"
                    target="_blank"
                  >
                    Content Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${MAIN_APP_LINK}/cookies-policy`}
                    title="Cookies Policy"
                    target="_blank"
                  >
                    Cookies Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${MAIN_APP_LINK}/terms-of-service`}
                    title="Terms of Service"
                    target="_blank"
                  >
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${MAIN_APP_LINK}/privacy-policy`}
                    title="Privacy Policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${MAIN_APP_LINK}/eula`}
                    title="EULA"
                    target="_blank"
                  >
                    EULA
                  </Link>
                </li>
              </ul>
            </Col>
            <Col span={24}>
              <p>&copy; {dayjs().year()} Imprint.live. All rights reserved.</p>
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
}
