import React, { useState } from "react";
import { Layout, Row, Col, Image, Modal } from "antd";
import styles from "./index.module.scss";
import bannerImage from "../../assets/banner-imprint.png";
import { MAIN_APP_LINK, SUBSCRIPTION_PLANS } from "../../constants";
import { Link } from "react-router-dom";
import PlanCard from "./components/plan-card";
import MainLayout from "../../components/layout/main-layout";
import ButtonComponent from "../../components/common/button";

const { Content } = Layout;

const videoData = [
  {
    title: "Welcome to Imprint",
    description: "The Future of Social Media",
    thumbnail: "/Images/welcome-imprint.png",
    videoUrl: "https://www.youtube.com/embed/dNcSnWKwge4",

   
  },
  {
    title: "How Imprint Works",
    description: "A Safe, Mindful Social Network",
    thumbnail: "/Images/Imprint-works.png",
    videoUrl: "https://www.youtube.com/embed/KEJ-VFZcjKE",
  },
  {
    title: "My Imprint",
    description: "A Personal Journey with Imprint",
    thumbnail: "/Images/My-Imprint.png",
    videoUrl: "https://www.youtube.com/embed/dNcSnWKwge4",
  },
];

function Home() {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setTimeout(() => {
      setSelectedVideo(null);
    }, 200);
  };

  return (
    <MainLayout>
      <Content
        className={styles.contentMain}
        style={{
          background: `url(${bannerImage}) no-repeat center center/cover `,
        }}
      >
        <Row justify="center" align="middle" className={styles.heroRow}>
          <Col xs={22} sm={20} md={16} lg={14} className={styles.textSection}>
            <h1>Change begins with an Imprint</h1>
            <p>
              Step into a social space where authenticity thrives, safety comes
              first, and every Imprint shapes a more mindful future. Are you
              ready to share your story and make an impact?
            </p>
            <Link to={`${MAIN_APP_LINK}/signup`}>
              <ButtonComponent
                {...{ type: "primary", text: "Sign Up", variant: "gold" }}
              />
            </Link>
          </Col>
        </Row>
        <Row justify="center" className={styles.imageRow}>
          <Col xs={22} sm={20} md={16} lg={12}>
            <Image
              src="/Images/Macbook.png"
              alt="Platform preview"
              className={styles.heroImage}
              preview={false}
            />
          </Col>
        </Row>
      </Content>

      <Content className={styles.secondSection}>
        <h2>What is Imprint?</h2>
        <Row gutter={[16, 16]} justify="center">
          {videoData.map((video, index) => (
            <Col xs={22} sm={20} md={8} key={index}>
              <div className={styles.card}>
                <div className={styles.videoWrapper}>
                  <Image
                    src={video.thumbnail}
                    alt={video.title}
                    className={styles.videoThumbnail}
                    onClick={() => handleVideoClick(video)}
                    preview={false}
                  />
                  <div
                    className={styles.playButton}
                    onClick={() => handleVideoClick(video)}
                  />
                </div>
                <div className={styles.descipVideo}>
                  <h3>{video.title}</h3>
                  <p>{video.description}</p>
                </div>
              </div>
            </Col>
          ))}
        </Row>

        <Modal
          open={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          width="85%"
          centered
          className={styles.videoModal}
          destroyOnClose={true}
          title={null}
        >
          <div className={styles.videoContainer}>
            {selectedVideo && isModalVisible && (
              <>
                <div className={styles.videoWrapper}>
                  <iframe
                    src={`${selectedVideo.videoUrl}?autoplay=1&rel=0&showinfo=0&modestbranding=1`}
                    title={selectedVideo.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className={styles.videoFrame}
                  />
                </div>
                <div className={styles.videoInfo}>
                  <h3>{selectedVideo.title}</h3>
                  <p>{selectedVideo.description}</p>
                </div>
              </>
            )}
          </div>
        </Modal>
      </Content>
      <Content>
        <div className={styles.membershipSection}>
          <Row justify="center">
            <Col lg={14} md={20} sm={20} xs={24}>
              <h2 className={styles.membershipHeading}>
                Become a member and start changing the world
              </h2>
              <p className={styles.membershipParagraph}>
                Join the Basic Plan to unlock your personal Imprint wall and start creating impact.
              </p>
            </Col>
          </Row>

          <Row gutter={[16, 16]} justify="center" className={styles.cardRow}>
            {SUBSCRIPTION_PLANS.map((PLAN, index) => (
              <Col xs={24} sm={12} md={6} lg={6} key={PLAN.id || index}>
                <PlanCard {...PLAN} />
              </Col>
            ))}
          </Row>

          <div className={styles.centerButton}>
            <Link to={`${MAIN_APP_LINK}/signup`}>
              <ButtonComponent
                {...{
                  type: "primary",
                  text: "Join the Movement",
                  variant: "gold",
                }}
              />
            </Link>
          </div>
        </div>
      </Content>
    </MainLayout>
  );
}

export default Home;
