export const SUBSCRIPTION_IMAGE_MARGIN = {
  basic: -10,
  pro: 90,
  promax: 50,
  "promax plus": 10,
};

export const API_URL = "https://app.imprint.live/backend/api/v2";

export const MAIN_APP_LINK = "https://app.imprint.live";

const BASE_FONT_SIZE = 20;
const SCALING_FACTOR = 4;
export const SUBSCRIPTION_PLANS = [
  {
    _id: {
      $oid: "67e3fd17865ad1be22549d4b",
    },
    planName: "Basic",
    price: 0.99,
    charityFactor: 0.1,
    perks: "1",
    description:
      "Show support & connect with others who share your values and causes.",
    MARGIN: -60,
    FONT_SIZE: BASE_FONT_SIZE + SCALING_FACTOR * 0,
    IMAGE_HEIGHT: "100px",
  },
  {
    _id: {
      $oid: "67e3fd17865ad1be22549d4c",
    },
    planName: "Pro",
    price: 4.99,
    charityFactor: 2,
    perks: "2",
    description:
      "Amplify your impact by promoting and driving awareness for the causes you care about.",
    MARGIN: -50,
    FONT_SIZE: BASE_FONT_SIZE + SCALING_FACTOR * 1,
    IMAGE_HEIGHT: "150px",
  },
  {
    _id: {
      $oid: "679b67baff45f15feaf7653d",
    },
    planName: "ProMax",
    price: 7.99,
    charityFactor: 5,
    perks: "5",
    description:
      "Inspire others, and represent key causes within the community.",
    MARGIN: -40,
    FONT_SIZE: BASE_FONT_SIZE + SCALING_FACTOR * 2,
    IMAGE_HEIGHT: "200px",
  },
  {
    _id: {
      $oid: "67e3fd17865ad1be22549d4a",
    },
    planName: "ProMax Plus",
    price: 12.99,
    charityFactor: 10,
    perks: "10",
    description:
      "Create and lead transformative movements towards greater causes.",
    MARGIN: -30,
    FONT_SIZE: BASE_FONT_SIZE + SCALING_FACTOR * 3,
    IMAGE_HEIGHT: "250px",
  },
];
